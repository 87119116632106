import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AuthStore } from '../auth-store';
import { withEnvironment } from '../extensions/with-environment';
import { OrderStore } from '../order-store';
import { SearchStore } from '../search-store';

export { useStores } from './root-store-context';

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore', {
    isDarkMode: false,
    authStore: types.optional(AuthStore, {}),
    orderStore: types.optional(OrderStore, {}),
    // locationStore: types.optional(LocationStore, undefined)
    searchStore: types.optional(SearchStore, {}),
    vendorId: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setVendor: (id: string) => {
      self.vendorId = id;
    },
    toggleDarkMode: () => {
      self.isDarkMode = !self.isDarkMode;
    },
    unsetVendor: () => {
      self.vendorId = '';
    },
  }));

type RootStoreType = Instance<typeof RootStoreModel>;

/**
 * The RootStore instance.
 */
export type RootStore = RootStoreType;
type RootStoreSnapshotType = SnapshotOut<RootStore>;
export type RootStoreSnapshot = RootStoreSnapshotType;

export const DefaultState = {
  authStore: {},
  orderStore: {},
  searchStore: {},
} as RootStore;
