import NextImage from 'next/image';
import React from 'react';

import type { CSS } from '@stitches/react';

import { styled } from '../../theme/stitches.config';

import type { ImageProps as NextImageProps } from 'next/image';
interface ImageProps extends NextImageProps {
  css?: CSS;
}

const StyledContainer = styled('div', {});

export const Image = ({
  height,
  layout = 'fill',
  src,
  width,
  ...props
}: ImageProps) => {
  return (
    <StyledContainer
      className="img"
      style={{ height, position: 'relative', width }}
      {...props}
    >
      <NextImage layout={layout} priority src={src} />
    </StyledContainer>
  );
};

export default Image;
