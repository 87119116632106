import React from 'react';

import { Footer, FooterProps } from '@goodfynd/react-web.layout.footer';
import {
  CascadeQuotes,
  CmsContent,
  Faqs,
  PromoCta,
  QuotesCarousel,
  StructuredColumns,
  TwoColumn,
  TwoPromoCta,
} from '@goodfynd/react-web.lib.content';
import { ButtonLink } from '@goodfynd/react-web.ui.button-link';

import env from '../../config/env';

import type { ComponentProps, ComponentType } from './types';

import type {
  CmsContentProps,
  FaqsProps,
  PromoCtaProps,
  QuotesCarouselProps,
  StructuredColumnsProps,
  TwoColumnProps,
  TwoPromoCtaProps,
  CascadeQuotesProps,
} from '@goodfynd/react-web.lib.content';
export default function renderCmsBodyComponent(
  index: number,
  componentType: ComponentType,
  fields: any,
  { containerCSS }: ComponentProps = {}
): JSX.Element | null {
  switch (componentType) {
    case 'button':
      const { button_href, button_text, return_to_page } = fields as any;

      return !!button_href && !!button_text ? (
        <ButtonLink
          href={`${button_href}${
            return_to_page ? `?returnUrl=${window.location.href}` : ''
          }`}
          key={componentType + index}
          size="md"
        >
          {button_text}
        </ButtonLink>
      ) : null;

    case 'cascading_event_quotes':
      return (
        <CascadeQuotes
          {...(fields as CascadeQuotesProps)}
          key={componentType + index}
        />
      );

    case 'content_section':
      return (
        <CmsContent
          containerCSS={containerCSS}
          {...(fields as CmsContentProps)}
          key={componentType + index}
        />
      );

    case 'content_with_image_columns':
      return (
        <StructuredColumns
          {...(fields as StructuredColumnsProps)}
          key={componentType + index}
        />
      );

    case 'event_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    case 'food_truck_owner_quotes_carousel':
      return (
        <QuotesCarousel
          {...(fields as QuotesCarouselProps)}
          key={componentType + index}
        />
      );

    case 'footer':
      return (
        <Footer
          drift_interaction_id={env.DRIFT_INTERACTION_ID}
          key={componentType + index}
          {...(fields.footers?.[0] as unknown as FooterProps)}
        />
      );

    case 'two_column_image_left':
      return (
        <PromoCta {...(fields as PromoCtaProps)} key={componentType + index} />
      );

    case 'two_column_image_right':
      return (
        <PromoCta
          image_position="right"
          {...(fields as PromoCtaProps)}
          key={componentType + index}
        />
      );

    case 'two_column_images':
      return (
        <TwoColumn
          {...(fields as TwoColumnProps)}
          key={componentType + index}
        />
      );

    case 'two_promo_cascading_images':
      return (
        <TwoPromoCta
          {...(fields as TwoPromoCtaProps)}
          key={componentType + index}
        />
      );

    case 'vendor_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    default:
      return null;
  }
}
