import type { FilterMenuOption } from '../components/FilterMenu/types';
import config from '../config';
import strings from '../config/strings';
import * as dateUtil from './date-util';
import * as stringUtil from './string-util';

import type { ScheduleItem } from '../types/search';
import type { VendorItem } from '../types/shared';
import type { PickupTimeOption } from '../types/vendors';
export const getPickupLocations = (
  item: ScheduleItem
): OperatingDayLocation[] => {
  const { operatingDayLocation } = item.map;
  const compareTime = new Date(operatingDayLocation.end as Date);
  const dateWithoutTime = dateUtil.removeTime(new Date());
  const maxDate = dateUtil.addDays(dateWithoutTime, 7, true);
  const hasCapacity = item.willOpenLater || item.isOpen;
  const pickupLocations = item.map.schedule.dayLocations.filter((day) => {
    const end = day.end as Date;
    const start = day.start as Date;
    return (
      ((day.hasLocation &&
        new Date(start as Date) >= dateWithoutTime &&
        !maxDate) ||
        (new Date(start) <= maxDate &&
          ((hasCapacity && new Date(end) >= compareTime) ||
            new Date(start) >= compareTime ||
            new Date(end) >= new Date()))) &&
      day.pickupCapacity > 0
    );
  });

  console.debug(pickupLocations);
  return pickupLocations;
};

export const getPickupTimes = (
  vendor: VendorItem,
  location: OperatingDayLocation
): PickupTimeOption[] => {
  const pickupCapacity = location.pickupCapacity || vendor.pickupCapacity || 0;
  const takenPickupTimes: { [key: string]: number } = {};
  for (const key in vendor.pickupTimes) {
    const time = vendor.pickupTimes[key];
    const timeKey = new Date(time).toISOString();
    takenPickupTimes[timeKey] = ++takenPickupTimes[timeKey] || 1;
  }

  const currentTime = new Date();
  const timeEnd = new Date(location?.end || dateUtil.endOfDay(currentTime));
  let timeStart = new Date(
    location?.start ||
      dateUtil.addMinutes(currentTime, currentTime.getMinutes() * -1)
  );
  const pickupTimesFound = [];
  let hours = null;
  let optionGroup: PickupTimeOption | undefined = undefined;
  while (pickupCapacity > 0 && timeStart < timeEnd) {
    const timeStartString = timeStart.toISOString();
    const totalTakenTimes = takenPickupTimes[timeStartString] || 0;
    if (
      dateUtil.addMinutes(timeStart, -10) > currentTime &&
      (!totalTakenTimes || totalTakenTimes < pickupCapacity)
    ) {
      if (hours !== timeStart.getHours()) {
        if (optionGroup) {
          pickupTimesFound.push(optionGroup);
        }

        hours = timeStart.getHours();
        optionGroup = {
          label: `Starting at ${dateUtil.format(
            timeStart,
            config.dateFormats.hourM
          )}`,
          options: [],
        };
      }

      // const spotsLeft = pickupCapacity - totalTakenTimes;
      optionGroup?.options?.push({
        label: `${dateUtil.format(timeStart, config.dateFormats.time)}`,
        // labelRight: `${spotsLeft} ${spotsLeft > 1 ? 'spots' : 'spot'} left`,
        labelRight:
          totalTakenTimes > 0
            ? `${totalTakenTimes} ${stringUtil.pluralize(
                'spot',
                totalTakenTimes
              )} taken`
            : '',
        value: timeStart.toString(),
      });
    }

    timeStart = dateUtil.addMinutes(timeStart, 15);
  }

  if (optionGroup) {
    pickupTimesFound.push(optionGroup);
  }

  return pickupTimesFound;
};

export const getPreOrderDayOptions = (firstOption?: FilterMenuOption) => {
  const today = dateUtil.startOfDay(new Date());
  const dates: Date[] = [...Array(6)].map((item, index) =>
    dateUtil.addDays(today, index, true)
  );

  const dayOptions: FilterMenuOption[] = dates.map((date) => ({
    label: `${strings.labels.open} ${dateUtil.getDayDisplay(
      date,
      config.dateFormats.weekDayMonthDay
    )}`,
    value: dateUtil.format(date, config.dateFormats.yearMonthDay),
  }));

  if (firstOption) {
    dayOptions.unshift(firstOption);
  }

  return dayOptions;
};

export const idEquals = (
  id: string,
  vendor: {
    id?: string;
    nameId?: string;
  }
) => {
  return !!id && (id === vendor.id || id === vendor.nameId);
};

export const getQrCodeLink = (id: string) =>
  `https://qr.goodfynd.com/media/vendor/${id}`;
