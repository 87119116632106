import { styled } from '../../theme/stitches.config';

export const StyledContainer = styled('div', {
  borderRadius: 4,
  padding: 12,
  defaultVariants: { type: 'info' },
  variants: {
    type: {
      brand: {
        backgroundColor: '$GreenBrand',
        svg: {
          color: '$Neutral00',
        },
        color: '$Neutral00 !important',
      },
      error: {
        backgroundColor: '$RedLightest',
        svg: {
          color: '$RedDarker',
        },
        color: '$RedDarker !important',
      },
      info: {
        backgroundColor: '$BlueAccent01',
        svg: {
          color: '$BlueAccent04',
        },
        color: '$BlueAccent04 !important',
      },
      success: {
        backgroundColor: '$GreenLightest',
        svg: {
          color: '$GreenDarker',
        },
        color: '$GreenDarker !important',
      },
      warning: {
        backgroundColor: '$YellowLightest',
        svg: {
          color: '$Neutral13',
        },
        color: '$Neutral13 !important',
      },
    },
  },
});
