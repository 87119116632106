import React, { useEffect, useMemo } from 'react';
import { useCart, useCartDispatch } from '../../context/cart-context';
import { useQueryClient } from 'react-query';
import { useTimer } from 'react-timer-hook';
import type { CSS } from '@stitches/react';
import { StyledTimer } from './styles';
import { Icon } from '@goodfynd/react-web.lib.ui';
import Text from '../../components/Text';
import strings from '../../config/strings';

const CartTimer = ({ css }: { css?: CSS }) => {
  const { cart } = useCart();
  const { refreshCart } = useCartDispatch();
  const currentTime = useMemo(() => new Date(), []);
  const expireTime = useMemo(
    () =>
      cart?.list
        .map((order) => order.expireTime && new Date(order.expireTime))
        .sort()[0] ?? currentTime,
    [cart?.list, currentTime]
  );

  const { isRunning, minutes, seconds, start, restart } = useTimer({
    expiryTimestamp: expireTime || currentTime,
    onExpire: () => console.warn('onExpire called'),
  });
  useEffect(() => {
    if (expireTime && expireTime > currentTime) {
      restart(expireTime);
    }
  }, [currentTime > expireTime, restart]);

  useEffect(() => {
    if (expireTime && !isRunning) {
      // alert(`cart expired ${config.emojis.sad}`);
      refreshCart();
    }
  }, [expireTime, isRunning, refreshCart]);

  return useMemo(
    () => (
      <StyledTimer css={css}>
        {!isRunning && <Icon ignoreDarkMode name="info" />}

        <Text inherit marginLeft={4} type="body2">
          {seconds > 0
            ? `${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')} ${strings.labels.beforePickupExpires}`
            : strings.labels.pickupTimeExpired}
        </Text>
      </StyledTimer>
    ),
    [isRunning, minutes, seconds]
  );
};

export default CartTimer;
