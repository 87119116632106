import config from '../config';
import { useStores } from '../stores';
import { GtmCartEvent } from '../types/gtm';
import {
  BasicCustomer,
  CreditCard,
  RemoveItemRequest,
  UpdatePickupRequest,
} from '../types/orders';
import { gtmUtil } from '../utils';
import { useOrderApi } from './api';

export const useOrderActions = () => {
  const orderApi = useOrderApi();
  const {
    orderStore: {
      productId: orderStoreProductId,
      request: orderStoreRequest,
      removeCard: orderStoreRemoveCard,
      resetOrder: orderStoreResetOrder,
      setBusy: orderStoreSetBusy,
      setCustomer: orderStoreSetCustomer,
      updated: orderStoreUpdated,
    },
  } = useStores();
  return {
    addItem: async (quantity: number, requests: string, name: string) => {
      try {
        if (!orderStoreProductId || !orderStoreRequest) {
          return Promise.reject();
        }

        orderStoreSetBusy();

        const cart = await orderApi.addItem(
          {
            ...orderStoreRequest,
            productId: orderStoreRequest.productId || orderStoreProductId,
            quantity,
            requests,
          },
          orderStoreRequest.orderId
        );

        orderStoreUpdated();

        // Track add to cart
        const order = cart.list.find((order) =>
          order.items.find((item) => item.id === orderStoreProductId)
        );
        const gtmData: GtmCartEvent = {
          event: 'add_to_cart',
          currency: config.app.settings.currencyCode,
          value: order?.total,
          order_id: order?.id,
          pickup_time: orderStoreRequest.pickupTime?.getTime(),
          schedule_id: orderStoreRequest.scheduleId,
          items: [
            {
              affiliation: order?.merchant.name,
              currency: config.app.settings.currencyCode,
              item_id: orderStoreProductId,
              item_name: name,
              location_id: order?.scheduleId,
              price: orderStoreRequest.value,
              quantity: orderStoreRequest.quantity,
              requests: requests,
            },
          ],
        };
        gtmUtil.pushEvent(gtmData);

        return cart;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    applyPromo: async (code: string) => {
      try {
        orderStoreSetBusy();
        const cart = await orderApi.applyPromo(code);
        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    removeItem: async (productId: string, request: RemoveItemRequest) => {
      try {
        const cart = await orderApi.removeItem(productId, request);

        // Track remove from cart
        const gtmData: GtmCartEvent = {
          event: 'remove_from_cart',
          currency: config.app.settings.currencyCode,
          value: request.value,
          order_id: request.orderId,
          items: [
            {
              currency: config.app.settings.currencyCode,
              item_id: orderStoreProductId as string,
              price: request.value,
              quantity: request.quantity,
            },
          ],
        };
        gtmUtil.pushEvent(gtmData);

        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      }
    },
    payCredit: async (payment: Partial<CreditCard>) => {
      try {
        orderStoreSetBusy();
        console.debug(payment);
        const { orders } = await orderApi.payCredit(payment);

        orderStoreResetOrder();
        return orders;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    removeCreditCard: async (card: CreditCard) => {
      try {
        const { success } = await orderApi.removeCreditCard(card);
        if (success) {
          card.id && orderStoreRemoveCard(card.id);
        }

        return success;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      }
    },
    updateCustomer: async (request: BasicCustomer) => {
      try {
        orderStoreSetBusy();
        const data = await orderApi.updateCustomer(request);
        orderStoreSetCustomer(data.customer);
        return data;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    updatePickupTime: async (request: UpdatePickupRequest) => {
      try {
        orderStoreSetBusy();
        const cart = await orderApi.updatePickupTime(request);
        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
  };
};
