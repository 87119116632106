import { Accordion } from '@mui/material';

import { styled } from '../../theme/stitches.config';
import Button from '../Button';

export const StyledAccordion = styled(Accordion, {
  '+ .MuiAccordion-root': {
    marginTop: 0,
  },
});

export const StyledButton = styled(Button, {
  '&.MuiButton-root': {
    minWidth: 318,
  },
});

export const StyledImageContainer = styled('div', {
  height: 248,
});

export const StyledMapContainer = styled('div', {
  alignItems: 'center',
  backgroundColor: '$GreenLightest',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  marginTop: 8,
  maxHeight: 150,
  overflow: 'hidden',
  '> img': {
    width: '100%',
  },
});

export const StyledModalContent = styled('div', {
  padding: '0 24px',
  paddingBottom: 112,
  '@sm': {
    padding: 16,
    paddingBottom: 88,
  },
});

export const StyledPickupTimeLabel = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledStickyBottom = styled('div', {
  alignItems: 'center',
  backgroundColor: '$White',
  display: 'flex',
  bottom: 0,
  height: 88,
  justifyContent: 'center',
  left: 0,
  padding: '16px 24px',
  position: 'absolute',
  shadow: 2,
  width: '100%',
  zIndex: 1,
  '@sm': {
    height: 72,
    padding: '8px 16px',
  },
});
