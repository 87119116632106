import React from 'react';

import Link from '../../../Link';
import Text from '../../../Text';
import type { FooterLink } from '../footer-links';
import { StyledSectionLabel } from './styles';

export interface FooterLinkListProps {
  children?: React.ReactNode;
  links: FooterLink[];
  sectionName?: string;
}

export default function FooterLinkList({
  sectionName,
  links,
}: FooterLinkListProps) {
  return (
    <div>
      {sectionName && <StyledSectionLabel>{sectionName}</StyledSectionLabel>}
      {links
        .filter((link) => !link.hide)
        .map((link) => (
          <Link
            href={link.href || ''}
            key={link.label}
            onClick={(e) => {
              if (link.onClick) {
                e.preventDefault();
                link.onClick();
              }
            }}
          >
            <Text type="footer">{link.label}</Text>
          </Link>
        ))}
    </div>
  );
}
