import React from 'react';

import { MenuItem } from '@mui/material';

import type { DropdownItemProps } from './types';

export default function DropdownItem({
  callback,
  item,
  children,
}: DropdownItemProps): JSX.Element {
  return <MenuItem onClick={callback}>{item || children}</MenuItem>;
}
