import React from 'react';

import { gtmUtil } from '../../utils';
import { Icon } from '../Icon';
import { StyledButton } from './styles';

import type { ButtonProps } from './types';

export const Button = ({
  align = 'center',
  children,
  display = 'primary',
  fixed = false,
  icon,
  marginTop,
  onClick,
  size = 'lg',
  track,
  variant = 'container',
  ...props
}: ButtonProps) => {
  const handleClick = (e: ClickButtonEvent) => {
    if (track) {
      gtmUtil.pushEvent({
        event: 'fynd_cta',
        ...track,
        id: track.id || props.id,
        label:
          track.label ||
          (typeof children === 'string' ? children : window.location.pathname),
        page_location:
          window.location.pathname + (window.location.search || ''),
      });
    }

    onClick(e);
  };

  return (
    <StyledButton
      aria-label={typeof children === 'string' ? children : undefined}
      align={align}
      css={{
        '&.MuiButton-root': {
          marginTop,
        },
      }}
      display={display}
      fixed={fixed}
      onClick={handleClick}
      size={size}
      variant={variant}
      {...props}
      id={track?.id ?? props.id}
    >
      {icon && (typeof icon === 'string' ? <Icon name={icon} /> : icon)}
      {children}
    </StyledButton>
  );
};

export default Button;
