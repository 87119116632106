import { styled } from '../../theme/stitches.config';

export const StyledTag = styled('span', {
  backgroundColor: '$Neutral02',
  borderRadius: 4,
  display: 'inline',
  fontSizeRem: 10,
  fontWeight: 800,
  lineHeightRem: 18,
  marginTop: 8,
  padding: '2px 8px',
  textTransform: 'uppercase',
  '+ span': {
    marginLeft: 8,
  },
  variants: {
    highlight: {
      true: {
        borderColor: '$RedDarker',
        borderStyle: 'solid',
        borderWidth: 2,
      },
    },
    variant: {
      important: {
        backgroundColor: '$RedLightest',
        color: '$RedDarker',
      },
    },
  },
});
