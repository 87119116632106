import React, { useEffect } from 'react';

import { Backdrop, Fade, Modal as MuiModal } from '@mui/material';

import { gtmUtil } from '../../utils';
import Theme from '../Theme';
import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledIconButton,
} from './styles';

import type { GtmActionEvent } from '../../types/gtm';
import type { ModalProps } from './types';

export default function Modal({
  children,
  close,
  containerCss = {
    maxWidth: 500,
  },
  disableBackdropClick,
  footer,
  gtmType,
  header,
  showClose = true,
  ...props
}: ModalProps) {
  useEffect(() => {
    if (props.open) {
      const gtmData: GtmActionEvent = {
        event: 'fynd_modal',
        type: gtmType,
        action: 'open',
      };
      gtmUtil.pushEvent(gtmData);
    }
  }, [gtmType, props.open]);

  const handleClose = (
    _event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }

    const gtmData: GtmActionEvent = {
      event: 'fynd_modal',
      type: gtmType,
      action: 'close',
    };
    gtmUtil.pushEvent(gtmData);
    close();
  };

  return (
    <MuiModal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
      onClose={handleClose}
      {...props}
    >
      <section>
        <Theme>
          <Fade in={props.open}>
            <StyledContainer css={containerCss}>
              <StyledBody>
                {header && <StyledHeader>{header}</StyledHeader>}

                {header && showClose && (
                  <StyledIconButton
                    aria-hidden={!props.open}
                    aria-label="Close Modal"
                    icon="close"
                    onClick={handleClose}
                    round
                    display="tertiary"
                  />
                )}

                <StyledContent>
                  {!header && showClose && (
                    <StyledIconButton
                      icon="close"
                      onClick={handleClose}
                      round
                      display="tertiary"
                    />
                  )}

                  {children}
                </StyledContent>

                {footer && <StyledFooter>{footer}</StyledFooter>}
              </StyledBody>
            </StyledContainer>
          </Fade>
        </Theme>
      </section>
    </MuiModal>
  );
}
