import { Button } from '@mui/material';

import { darkTheme, styled } from '../../theme/stitches.config';

export const StyledButton = styled(Button, {
  '&.MuiButton-root': {
    alignSelf: 'center',
    backgroundColor: '$GreenBrand',
    borderRadius: 8,
    borderWidth: 0,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.10), 0 1px 2px rgba(0, 0, 0, 0.10)',
    color: '$Neutral00',
    cursor: 'pointer',
    fontFamily: 'Avenir',
    fontWeight: 800,
    justifyContent: 'center',
    outline: 'none',
    padding: '0 16px',
    textAlign: 'center',
    textTransform: 'none',
    '&[disabled]': {
      backgroundColor: '$Neutral02',
      color: '$Neutral05',
      cursor: 'default',
      '&:hover': {
        opacity: 1,
      },
    },
    '&:hover': {
      backgroundColor: '$GreenBrand',
      opacity: 0.75,
    },
  },
  variants: {
    align: {
      center: {
        '&.MuiButton-root': {
          alignSelf: 'center',
        },
      },
      left: {
        '&.MuiButton-root': {
          alignSelf: 'flex-start',
        },
      },
      right: {
        '&.MuiButton-root': {
          alignSelf: 'flex-end',
        },
      },
    },
    fullWidth: {
      true: {
        '&.MuiButton-root': {
          width: '100%',
        },
      },
    },
    fixed: {
      true: {
        '&.MuiButton-root': {
          maxWidth: 343,
          minWidth: 343,
        },
      },
    },
    display: {
      destructive: {
        '&.MuiButton-root': {
          backgroundColor: '$YellowBrand',
          color: '$Neutral13',
          [`.${darkTheme} &`]: {
            color: '$Neutral00',
          },
          '&:hover': {
            backgroundColor: '$YellowBrand',
          },
        },
      },
      filter: {
        '&.MuiButton-root': {
          backgroundColor: '$White',
          borderColor: '$Neutral05',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 16,
          boxShadow: 'none',
          fontSizeRem: 14,
          fontWeight: 400,
          lineHeightRem: 21,
          padding: '4px 12px 5px',
          '&:hover': {
            backgroundColor: '$White',
          },
        },
      },
      primary: {
        '&.MuiButton-root': {
          backgroundColor: '$GreenBrand',
          '&:hover': {
            backgroundColor: '$GreenBrand',
          },
        },
      },
      secondary: {
        '&.MuiButton-root': {
          backgroundColor: '$RedBrand',
          '&:hover': {
            backgroundColor: '$RedBrand',
          },
        },
      },
      tertiary: {
        '&.MuiButton-root': {
          backgroundColor: '$Neutral02',
          color: '$Neutral13',
          '&:hover': {
            backgroundColor: '$Neutral02',
          },
        },
      },
    },
    round: {
      true: {
        '&.MuiButton-root': {
          borderRadius: '100%',
          height: 40,
          maxWidth: 40,
          minWidth: 40,
          padding: '8px !important',
        },
      },
    },
    size: {
      lg: {
        '&.MuiButton-root': {
          padding: '15px 16px 17px',
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
        },
      },
      md: {
        '&.MuiButton-root': {
          fontSize: '1rem',
          lineHeight: '1.5rem',
          padding: '11px 16px 13px',
        },
      },
      sm: {
        '&.MuiButton-root': {
          fontSizeRem: 14,
          lineHeightRem: 21,
          padding: '7px 16px 8px',
        },
      },
    },
    variant: {
      outlined: {
        '&.MuiButton-root': {
          backgroundColor: 'transparent',
          borderColor: '$GreenBrand',
          borderWidth: 1,
          boxShadow: 'none',
          color: '$GreenBrand',
          '&:hover': {
            backgroundColor: '$White',
          },
        },
      },
    },
  },
});
