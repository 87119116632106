import React from 'react';

import { unicode } from '@goodfynd/react-web.lib.strings';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { Text } from '@goodfynd/react-web.typography.text';
import { ButtonLink } from '@goodfynd/react-web.ui.button-link';
import { gtmUtil } from '@goodfynd/react-web.utils.gtm-util';
import { numberUtil } from '@goodfynd/react-web.utils.number-util';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Icon } from '../../components/Icon';
import app from '../../config/app';
import routes from '../../config/routes';
import strings from '../../config/strings';
import { StyledInputTip, StyledItemRow, StyledTooltip, StyledTotalsContainer } from './styles';

import type { OrderTotalsProps } from './types';
export default function OrderTotals({
  discount,
  fees,
  feesTotal,
  orders,
  showCheckout,
  showTip,
  subTotal,
  total,
  tip = 0,
  tipAmount = 0,
  updateTipAmount,
  ...props
}: OrderTotalsProps): JSX.Element {
  const [feesTipOpen, setFeesTipOpen] = React.useState(false);
  const handleFeesTipClose = () => {
    setFeesTipOpen(false);
  };

  const handleFeesTipOpen = () => {
    setFeesTipOpen(true);
  };

  const handleCheckout = () => {
    orders?.forEach((order) => {
      gtmUtil.pushEvent({
        event: 'begin_checkout',
        currency: app.settings.currencyCode,
        order_id: order.id,
        tip,
        value: total,
        items: order.items.map((item, index) => ({
          affiliation: order.merchant.name,
          currency: app.settings.currencyCode,
          index,
          item_category: item.categoryName,
          item_id: item.productId,
          item_name: item.name,
          location_id: order.scheduleId,
          price: item.price.value,
          quantity: item.price.quantity,
        })),
      });
    });
  };

  const feesAmount =
    feesTotal || fees.map((fee) => fee.value).reduce((a, b) => a + b, 0);
  return (
    <StyledTotalsContainer {...props}>
      <StyledItemRow>
        <Text type="subh2">{strings.labels.subtotal}</Text>

        <Text type="subh2">{numberUtil.renderPrice(subTotal)}</Text>
      </StyledItemRow>

      {discount > 0 && (
        <StyledItemRow>
          <Text type="subh2">{strings.labels.discount}</Text>

          <Text css={{ color: '$GreenBrand' }} type="subh2">
            {unicode.dashEn}
            {numberUtil.renderPrice(discount)}
          </Text>
        </StyledItemRow>
      )}

      {tip > 0 && (
        <StyledItemRow>
          <Text type="subh2">{strings.labels.tip}</Text>

          <Text type="subh2">{numberUtil.renderPrice(tip)}</Text>
        </StyledItemRow>
      )}

      {feesAmount > 0 && (
        <StyledItemRow>
          <ClickAwayListener onClickAway={handleFeesTipClose}>
            <div
              onClick={handleFeesTipOpen}
              onMouseOver={handleFeesTipOpen}
              style={{ cursor: 'pointer' }}
            >
              <StyledTooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleFeesTipClose}
                open={feesTipOpen}
                PopperProps={{
                  disablePortal: true,
                }}
                title={
                  <>
                    {fees.map((fee, index) => (
                      <>
                        <Text
                          css={index ? { marginTop: 8 } : undefined}
                          type="subh2"
                        >
                          {fee.name}:&nbsp;{numberUtil.renderPrice(fee.value)}
                        </Text>
                        <Text type="caption">
                          <em>{fee.description}</em>
                        </Text>
                      </>
                    ))}
                  </>
                }
              >
                <StyledFlex gap={4}>
                  <Text type="subh2">{strings.labels.taxesAndFees}</Text>
                  <Icon name="info" size={16} />
                </StyledFlex>
              </StyledTooltip>
            </div>
          </ClickAwayListener>

          <Text type="subh2">{numberUtil.renderPrice(feesAmount)}</Text>
        </StyledItemRow>
      )}

      {showTip && total > 0 && (
        <StyledInputTip
          onChange={(tipAmount) => updateTipAmount?.(tipAmount)}
          subTotal={subTotal}
        />
      )}

      <StyledItemRow css={{ marginTop: '24px !important' }}>
        <Text
          css={{
            fontSizeRem: 22,
            lineHeightRem: 28,
          }}
          type="subh2"
        >
          {strings.labels.total}
        </Text>

        <Text
          css={{
            fontSizeRem: 22,
            lineHeightRem: 28,
          }}
          type="subh2"
        >
          {numberUtil.renderPrice(total + tipAmount)}
        </Text>
      </StyledItemRow>

      {showCheckout && (
        <ButtonLink
          fullWidth
          marginTop={16}
          href={routes.checkout.home}
          onClick={handleCheckout}
        >
          {strings.labels.proceedToCheckout}
        </ButtonLink>
      )}
    </StyledTotalsContainer>
  );
}
