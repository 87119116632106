import React, { useCallback, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import strings from '../../config/strings';
import { useWebShare } from '../../hooks/useWebShare';
import * as gtmUtil from '../../utils/gtm-util';
import Alert from '../Alert';
import { Icon } from '../Icon';
import Loading from '../Loading';
import { StyledButton, StyledIconButton } from './styles';

import type { IconName } from '../Icon/types';
import type { ShareButtonProps } from './types';
import type { GtmShareItemEvent } from '../../types/gtm';

export default function ShareButton({
  buttonStyle = 'default',
  item,
  text,
  title,
  url = window.location.href,
  ...props
}: ShareButtonProps) {
  const [success, setSuccess] = useState(false);
  const { busy, isSupported, share } = useWebShare();

  const handleClick = useCallback(
    async (e: ClickButtonEvent) => {
      const gtmData: GtmShareItemEvent = {
        event: 'fynd_share',
        item_id: item.id,
        item_name: item.uniqueName || item.name,
        method: '',
      };
      gtmUtil.pushEvent(gtmData);

      if (!isSupported) {
        return e.preventDefault();
      }

      share({ text, title, url });
    },
    [isSupported, item.id, item.name, item.uniqueName, share, text, title, url]
  );

  const handleCopy = useCallback(() => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }, []);

  const commonProps = useMemo(
    () => ({
      disabled: busy,
      onClick: handleClick,
      ...props,
    }),
    [busy, handleClick, props]
  );

  const renderButton = useCallback(() => {
    const iconName: IconName = /iPhone|iPad|iPod/i.test(
      global.window?.navigator.userAgent
    )
      ? 'share_ios'
      : 'share_android';

    switch (buttonStyle) {
      case 'default':
        return (
          <StyledButton
            disableElevation
            display="tertiary"
            size="sm"
            startIcon={
              busy ? (
                <Loading size={16} type="circle" />
              ) : (
                <Icon name={iconName} size={18} />
              )
            }
            {...commonProps}
          >
            {success ? (
              <Alert type="success">{strings.labels.copiedToClipboard}</Alert>
            ) : (
              strings.labels.share
            )}
          </StyledButton>
        );

      case 'icon':
        return (
          <StyledIconButton
            display="tertiary"
            icon={
              busy ? (
                <Loading size={16} type="circle" />
              ) : success ? (
                'check'
              ) : (
                iconName
              )
            }
            round
            {...commonProps}
          >
            {success && <Alert type="success">{strings.labels.copied}</Alert>}
          </StyledIconButton>
        );
    }
  }, [busy, buttonStyle, commonProps, success]);

  return isSupported ? (
    renderButton()
  ) : (
    <CopyToClipboard onCopy={handleCopy} text={url}>
      {renderButton()}
    </CopyToClipboard>
  );
}
