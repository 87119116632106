import React from 'react';
import Lottie from 'react-lottie-player';

import { Box, CircularProgress, LinearProgress } from '@mui/material';

import loadingAnimation from './animations/loading.json';
import type { LoadingProps } from './types';

export default function Loading({
  animationData = loadingAnimation,
  height = 2,
  size = 24,
  type = 'lottie',
  ...props
}: LoadingProps) {
  switch (type) {
    case 'circle': {
      return (
        <aside>
          <CircularProgress size={size} {...props} />
        </aside>
      );
    }

    case 'line': {
      return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress style={{ height }} {...props} />
        </Box>
      );
    }

    case 'lottie': {
      return (
        <aside>
          <Lottie
            animationData={animationData}
            loop
            play
            style={{
              margin: '0 auto',
              height: 131,
              width: 131,
              ...props.style,
            }}
          />
        </aside>
      );
    }
  }
}
