import React, { useState } from 'react';

import MuiDrawer from '@mui/material/Drawer';
import NoSsr from '@mui/material/NoSsr';

import { gtmUtil } from '../../../utils';
import Theme from '../../Theme';
import { StyledAnchorButton, StyledCloseButton, StyledHeader, StyledScrollContent } from './styles';

import type { DrawerProps } from './types';
import type { GtmActionEvent } from '../../../types/gtm';

const Drawer = ({
  anchor,
  anchorCSS,
  children,
  footer,
  gtmType,
  header,
  onClose,
  onOpen,
  position,
  showClose = true,
}: DrawerProps) => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (force?: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    const gtmData: GtmActionEvent = {
      event: 'fynd_modal',
      type: gtmType,
      action: force || !open ? 'open' : 'close',
    };
    gtmUtil.pushEvent(gtmData);

    setOpen(force ? force : !open);
    force || !open ? onOpen?.() : onClose?.();
  };

  return (
    <NoSsr>
      <StyledAnchorButton
        css={anchorCSS}
        onClick={toggleDrawer()}
        variant="outlined"
      >
        {anchor}
      </StyledAnchorButton>

      <MuiDrawer
        anchor={position}
        open={open}
        onClose={toggleDrawer()}
        // onOpen={toggleDrawer(true)}
      >
        <Theme>
          {showClose && (
            <StyledCloseButton
              icon="close"
              onClick={toggleDrawer()}
              variant="outlined"
            />
          )}

          {header && <StyledHeader>{header}</StyledHeader>}

          <StyledScrollContent>{children}</StyledScrollContent>

          {footer}
        </Theme>
      </MuiDrawer>
    </NoSsr>
  );
};

export default Drawer;
