import React from 'react';

import { Icon } from '../Icon';
import { StyledContainer, StyledLabelContainer, StyledText } from './styles';

import type { IconLabelProps } from './types';

export default function IconLabel({
  children,
  icon,
  iconColor,
  iconSize,
  showIcon = true,
  ...props
}: IconLabelProps) {
  return (
    <StyledContainer {...props}>
      {showIcon && <Icon color={iconColor} name={icon} size={iconSize} />}

      <StyledLabelContainer>
        {typeof children === 'string' ||
        (Array.isArray(children) && typeof children[0] === 'string') ? (
          <StyledText inherit type="body2">
            {children}
          </StyledText>
        ) : (
          children
        )}
      </StyledLabelContainer>
    </StyledContainer>
  );
}
