const endpoints = {
  accounts: {
    base: 'consumer-site/accounts/v2/:id',
    create: 'consumer-site/accounts/v2',
    userInfo: 'consumer-site/accounts/v2/user-info',
    deleteProfile: 'consumer-site/accounts/v2/request-deletion',
  },
  carts: {
    cart: 'consumer-site/carts/v2/:id',
  },
  checkout: {
    cash: {
      end: 'checkout/cash/end/:id',
      start: 'checkout/cash/start/:id',
    },
    token: 'checkout/token',
    pay: {
      credit: 'checkout/pay/credit',
      inperson: 'checkout/pay/inperson',
      new: '',
      paypal: 'checkout/pay/paypal',
      update: 'checkout/pay/update/:id/:amount',
      vendor: 'checkout/pay/credit',
    },
  },
  cms: {
    base: 'https://api.buttercms.com/v2',
    collection: '/api/cms/collection',
    link: '/api/cms/link',
    pages: 'pages/:slug', //?auth_token=your_token',
    retrieve: '/api/cms/retrieve',
    search: '/api/cms/search',
  },
  twilio: {
    lookup:
      'communications/validate/phone-number/:phoneNumber/country-code/:countryCode',
  },
  customers: {
    base: 'customers/:id',
  },
  locations: {
    byIP: 'https://ipapi.co/json',
  },
  events: {
    byLot: 'consumer-site/events/v2/lot/:lotId',
    search: 'consumer-search/lot',
  },
  invoices: {
    finalize: 'invoices/v2/:id/finalize/:tip',
    quote: {
      accept: 'invoices/v2/quote/:id/accept',
      create: 'invoices/v2/quote',
      edit: 'invoices/v2/quote/:id/edit',
    },
    tip: 'invoices/v2/:id/tip/:tip',
  },
  orders: {
    abandon: 'orders/abandon/:id',
    base: 'orders/v2/:id',
    cancel: 'orders/cancel/:id',
    clear: 'orders/clear/:id',
    complete: 'orders/complete/:id/:transactionId',
    count: 'orders/count',
    like: 'orders/like/:id',
    merchants: 'orders/merchants/:id',
    pickup: 'orders/pickup',
    prepare: 'orders/prepare/:id',
    promo: 'consumer-site/orders/v2/promo/:code',
    ready: 'orders/ready/:id',
    receipt: {
      image: 'orders/receipt/image/:transactionId/:chefView',
    },
    refund: 'orders/refund/:id',
    remove: 'orders/remove/:id',
    reports: 'orders/export',
    search: 'consumer-site/order-search/v2/consumer',
    send: {
      receipt: 'orders/send/receipt/:id',
    },
    track: {
      print: 'orders/track/print/:id',
    },
    transactions: 'orders/transactions',
  },
  organizations: {
    territories: 'consumer-site/territories/v2',
  },
  pos: {
    capture: 'pos/capture/:vendorId',
    invoice: 'pos/invoice/:id',
    pay: 'pos/pay/:id',
    payCash: 'pos/cash/:vendorId',
    token: 'pos/token/:id',
  },
  products: {
    base: 'products/:id/:includeSchedule',
    search: 'consumer-search/product',
  },
  stripe: {
    card: 'customers/card',
  },
  vendors: {
    base: 'consumer-site/vendors/v2/:id',
    menu: 'menu/:id',
    orgMenu: 'consumer-site/menu/v2/:id',
    orderingSchedules: 'consumer-search/vendor',
    products: 'menu/vendor/:vendorId',
    searchAll: 'consumer-search/vendor/all',
  },
  likes: {
    base: 'likes',
    delete: 'likes/entity/:entityId/type/:entityType',
    likedVendors: 'likes/vendors',
  },
  lots: {
    base: 'public/lots/v2/:id',
  },
  schedules: {
    base: 'public/schedules/v2/:id',
  },
};

export default endpoints;
