import React from 'react';

import strings from '../../../config/strings';
import { LEGAL_LINKS } from '../Footer/footer-links';
import Link from '../../Link';
import Text from '../../Text';
import { StyledLegal } from './styles';

export default function FooterLegal() {
  return (
    <StyledLegal>
      <ul>
        {LEGAL_LINKS.map((link) => (
          <li key={link.label}>
            <Link href={link.href || ''}>
              <Text type="footer">{link.label}</Text>
            </Link>
          </li>
        ))}
      </ul>

      <Text type="footer">
        &copy;{new Date().getFullYear()} {strings.app.nameLegal}
      </Text>
    </StyledLegal>
  );
}
