import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { StyledText, Text } from '@goodfynd/react-web.typography.text';
import { Image } from '@goodfynd/react-web.ui.image';
import { Link } from '@goodfynd/react-web.ui.link';

import config from '../../config';
import images from '../../config/images';
import routes from '../../config/routes';
import strings from '../../config/strings';
import { useOrderApi } from '../../hooks/api';
import OrderItemRow from '../../hooks/useCart/OrderItemRow';
import OrderMerchant from '../../hooks/useCart/OrderMerchant';
import OrderTotals from '../../hooks/useCart/OrderTotals';
import PromoForm from '../../hooks/useCart/PromoForm';
import { StyledEmptyContainer } from '../../hooks/useCart/styles';
import { OrderMerchantProps, OrderTotalsProps, PromoFormProps } from '../../hooks/useCart/types';
import { useOrderActions } from '../../hooks/useOrderActions';
import { CartResponse } from '../../services/api/types';
import { CartOrder, OrderItem } from '../../types/cart';
import { RemoveItemRequest, UpdatePickupRequest } from '../../types/orders';
import { storageUtil } from '../../utils';
import { minutesToMS } from '../../utils/number-util';
import { useApp } from '../app-context';
import { CartContextValue, CartDispatchContextValue, CartProviderProps } from './type';

import type { CSS } from '@stitches/react';
export const CartContext = createContext<CartContextValue | undefined>(
  undefined
);
export const CartDispatchContext = createContext<
  CartDispatchContextValue | undefined
>(undefined);

export default function CartProvider({
  children,
  customer,
}: CartProviderProps) {
  const api = useOrderApi();
  const queryClient = useQueryClient();

  const orderActions = useOrderActions();

  const { isLoggedIn } = useApp();
  const [tipAmount, updateTipAmount] = useState(0);
  const queryKey = useMemo(
    () => [config.queryKeys.cart, isLoggedIn],
    [isLoggedIn]
  );
  const { data, isError, isFetched, isLoading, refetch } = useQuery<
    CartResponse,
    unknown
  >(
    queryKey,
    () => {
      const cartId = storageUtil.local.get(
        config.storageKeys.guestOrder,
        ''
      ) as string;
      return api.getCart(cartId);
    },
    {
      staleTime: minutesToMS(1),
    }
  );
  useEffect(() => {
    if (!data?.total) {
      updateTipAmount(0);
    }
  }, [data?.total]);

  const setCart = useCallback(
    (cart: CartResponse) => {
      queryClient.setQueryData(queryKey, cart);
    },
    [queryClient, queryKey]
  );

  const resetCart = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const removeItem = useCallback(
    async (productId: string, request: RemoveItemRequest) => {
      const cart = await orderActions.removeItem(productId, request);
      setCart(cart);
    },
    [orderActions, setCart]
  );
  const updatePickupTime = useCallback(
    async (request: UpdatePickupRequest) => {
      const cart = await orderActions.updatePickupTime(request);

      setCart(cart);
    },
    [orderActions, setCart]
  );

  const applyPromo = useCallback(
    async (promo: string) => {
      const cart = await orderActions.applyPromo(promo);
      setCart(cart);
    },
    [orderActions, setCart]
  );

  const renderEmpty = useCallback(
    (showCta = false) => (
      <StyledEmptyContainer>
        <Image
          alt="Find food"
          height={200}
          width={200}
          src={images.misc.foodGalaxy}
        />
        <Text marginTop={24} type="subh2">
          {strings.labels.bagEmpty}
        </Text>
        <Text marginTop={4} type="body2">
          {strings.messages.goFindFood}
        </Text>

        {showCta && (
          <Link
            css={{ fontWeight: 800, marginTop: 16 }}
            href={routes.search.products}
          >
            {strings.labels.findMoreFood}!
          </Link>
        )}
      </StyledEmptyContainer>
    ),
    []
  );

  const renderItem = useCallback(
    (order: CartOrder, css?: CSS) =>
      function Item(item: OrderItem, index: number) {
        return (
          <OrderItemRow
            border={index === 0}
            css={css}
            item={item}
            key={item.id + index}
            order={order}
            removeItem={removeItem}
          />
        );
      },
    [removeItem]
  );

  const renderMerchant = useCallback(
    (props: OrderMerchantProps) => {
      return (
        <OrderMerchant
          {...props}
          updatePickupTime={updatePickupTime}
          customer={customer}
          key={props.order.id}
        />
      );
    },
    [customer, updatePickupTime]
  );

  const renderOrder = useCallback(
    (props: OrderMerchantProps) => {
      const { css, order } = props;
      return (
        <React.Fragment key={order.id}>
          {renderMerchant(props)}
          {order.items.map(renderItem(order, css))}
        </React.Fragment>
      );
    },
    [renderItem, renderMerchant]
  );

  const renderPromoForm = useCallback(
    (props?: PromoFormProps) => {
      if (!isLoggedIn) {
        return (
          <StyledText css={{ marginTop: 16 }}>
            Have a promo code? &nbsp;
            <Link
              href={`${routes.account.signIn}?returnUrl=${window.location.href}`}
            >
              Log in
            </Link>
            &nbsp; to apply it to your cart.
          </StyledText>
        );
      }
      const promo = data?.list.find((order) => !!order.promo)?.promo;
      return (
        <PromoForm applyPromo={applyPromo} appliedPromo={promo} {...props} />
      );
    },
    [applyPromo, data?.list, isLoggedIn]
  );

  const renderTotals = useCallback(
    (props: OrderTotalsProps) => {
      return (
        <OrderTotals
          key={props.id}
          orders={data?.list}
          tipAmount={props.showTip ? tipAmount : 0}
          updateTipAmount={updateTipAmount}
          {...props}
        />
      );
    },
    [data?.list, tipAmount]
  );

  const isFree =
    !!data?.discount && !!!data.total && data?.subTotal === data?.discount;

  return (
    <CartContext.Provider
      value={{
        cart: data,
        isError,
        isFetched,
        isFree,
        isLoading,
        secondsRemaining: 0, // timer.seconds,
        tipAmount,
      }}
    >
      <CartDispatchContext.Provider
        value={{
          refreshCart: refetch,
          setCart,
          resetCart,
          renderEmpty,
          renderItem,
          renderMerchant,
          renderOrder,
          renderPromoForm,
          renderTotals,
          updatePickupTime: (
            merchantId: string,
            scheduleId: string,
            pickupTime: Date
          ) => {
            const order = data?.list.find(
              (order) => order.merchant.id == merchantId
            );
            if (order) {
              updatePickupTime({
                id: order.id,
                scheduleId,
                pickupTime,
              });
            }
          },
        }}
      >
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
}
