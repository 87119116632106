import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import * as stringUtil from '../../utils/string-util';

import type { SearchRequest } from '../../types/search';
import type { VendorScheduleRequest } from '../../types/vendors';
import type {
  MetaResponse,
  VendorProductsResponse,
  VendorResponse,
  VendorSearchResponse,
  VendorScheduleSearchResponse,
} from '../../services/api/types';

export const useVendorApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<VendorResponse> {
        try {
          const data: VendorResponse = await api.get(
            stringUtil.replace(endpoints.vendors.base, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },

      async getScheduleDetails(id: string): Promise<VendorResponse> {
        try {
          const data: VendorResponse = await api.get(
            stringUtil.replace(endpoints.schedules.base, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },

      async getOrgMenu(id: string): Promise<VendorProductsResponse> {
        try {
          const data: VendorProductsResponse = await api.get(
            stringUtil.replace(endpoints.vendors.orgMenu, {
              ':id': id,
            })
          );

          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getMenu(id: string): Promise<VendorProductsResponse> {
        try {
          const data: VendorProductsResponse = await api.get(
            stringUtil.replace(endpoints.vendors.menu, {
              ':id': id,
            })
          );

          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getOpenVendors(
        query: VendorScheduleRequest
      ): Promise<VendorScheduleSearchResponse> {
        try {
          const data: VendorScheduleSearchResponse = await api.get(
            endpoints.vendors.orderingSchedules,
            {
              rows: 48,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );

          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getNearbyVendors(date = ''): Promise<VendorSearchResponse> {
        try {
          const data: VendorSearchResponse = await api.get(
            stringUtil.replace(endpoints.vendors.orderingSchedules, {
              ':date': date,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async refreshMenu(id: string): Promise<VendorProductsResponse> {
        try {
          const data: VendorProductsResponse = await api.post(
            stringUtil.replace(endpoints.vendors.menu, {
              ':id': id,
            })
          );

          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async refreshOrgMenu(id: string): Promise<VendorProductsResponse> {
        try {
          const data: VendorProductsResponse = await api.post(
            stringUtil.replace(endpoints.vendors.orgMenu, {
              ':id': id,
            })
          );

          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async search(query: SearchRequest): Promise<VendorSearchResponse> {
        try {
          const data: VendorSearchResponse = await api.get(
            endpoints.vendors.searchAll,
            {
              rows: 24,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },
    };
  }, [api]);
};
