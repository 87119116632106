import pick from 'lodash/pick';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { StyledFlexBetweenV } from '@goodfynd/react-web.styles';
import { Text } from '@goodfynd/react-web.typography.text';
import { gtmUtil } from '@goodfynd/react-web.utils.gtm-util';

import config from '../../../config';
import strings from '../../../config/strings';

import { Icon } from '../../Icon';
import Drawer from '../../layout/Drawer';
import {
  StyledContainer,
  StyledTitleContainer,
  StyledTotalsContainer,
} from './styles';

import type { GtmEvent } from '../../../types/gtm';
import type { CartProps } from './types';
import { useCart, useCartDispatch } from '../../../context/cart-context';
import CartTimer from '../../../hooks/useCart/CartTimer';
export const Cart = observer((props: CartProps) => {
  const { cart } = useCart();
  const { renderTotals } = useCartDispatch();
  const { renderEmpty, renderOrder } = useCartDispatch();

  const renderContent = () => {
    if (!cart?.itemTotal) {
      return renderEmpty();
    }

    return (
      <>
        <CartTimer />

        <StyledFlexBetweenV css={{ gap: 24 }}>
          {cart.list?.map((order, index) => renderOrder({ index, order }))}
        </StyledFlexBetweenV>

        <StyledTotalsContainer>
          {renderTotals({
            ...pick(cart, [
              'discount',
              'fees',
              'feesTotal',
              'id',
              'subTotal',
              'tipAmount',
              'total',
            ]),
            showCheckout: true,
          })}
        </StyledTotalsContainer>
      </>
    );
  };

  const merchantItems = (cart?.list || []).map((order) => order.items);
  return (
    <Drawer
      anchor={
        <Icon
          badge={cart?.itemTotal}
          badgeStyle={{
            fontSizeRem: 11,
            fontWeight: 800,
            lineHeightRem: 20,
          }}
          name="bag"
        />
      }
      anchorCSS={{
        '&.MuiButton-root': {
          backgroundColor: 'transparent',
          margin: '0 8px',
        },
      }}
      gtmType="cart"
      onOpen={() => {
        const gtmData: GtmEvent = {
          event: 'view_cart',
          currency: config.app.settings.currencyCode,
          tax: cart?.taxAmount,
          value: cart?.total,
          items: merchantItems.flat(1).map((item, index) => ({
            affiliation: item.merchant?.name,
            currency: config.app.settings.currencyCode,
            index,
            item_category: item.categoryName,
            item_id: item.productId,
            item_name: item.name,
            price: item.price.value,
            quantity: item.price.quantity,
          })),
        };
        gtmUtil.pushEvent(gtmData);
      }}
      position="right"
      {...props}
    >
      <StyledContainer>
        <StyledTitleContainer>
          <Text as="h5" type="h5">
            {strings.titles.yourBag}&nbsp;({cart?.itemTotal || 0})
          </Text>
        </StyledTitleContainer>

        {renderContent()}
      </StyledContainer>
    </Drawer>
  );
});
