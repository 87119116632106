import { InputTip } from '@goodfynd/react-web.checkout.input-tip';
import { DefaultColors, styled } from '@goodfynd/react-web.theme';
import { Text } from '@goodfynd/react-web.typography.text';
import Stack from '@mui/material/Stack';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import { Icon } from '../../components/Icon';
import IconLabel from '../../components/IconLabel';

export const StyledCartItem = styled('div', {});

export const StyledEmptyContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
});

export const StyledIconCircle = styled('div', {
  border: '4px solid $Neutral05',
  borderRadius: '50%',
  height: 24,
  width: 24,
});

export const StyledIconCircleActive = styled(Icon, {
  backgroundColor: '$GreenBrand',
  borderRadius: '50%',
  height: 24,
  padding: 4,
  width: 24,
});

export const StyledIconLabel = styled(IconLabel, {
  marginTop: 16,
  [`+ ${IconLabel}`]: {
    marginTop: 12,
  },
});

export const StyledInputTip = styled(InputTip, {
  marginTop: 12,
});

export const StyledItemContainer = styled('section', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 24px',
});

export const StyledItemInfoContainer = styled('div', {
  borderColor: '$Neutral05',
  borderWidth: 1,
  flex: 1,
  marginLeft: 16,
  paddingTop: 24,
  variants: {
    border: {
      true: {
        borderTopStyle: 'solid',
      },
    },
  },
});

export const StyledItemOptionText = styled(Text, {
  color: '$Neutral09',
  fontSizeRem: 14,
  lineHeightRem: 24,
});

export const StyledItemRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledMerchantContainer = styled('section', {
  padding: 24,
  paddingBottom: 0,
});

export const StyledOrderConfirmation = styled('section', {
  borderColor: '$Neutral05',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  marginBottom: 24,
  paddingBottom: 24,
});

export const StyledPromoForm = styled('form', {
  padding: '8px 0',
});

export const StyledPromoInput = styled('div', {
  alignItems: 'center',
  display: 'flex',
  marginTop: 16,
});

export const StyledQuantity = styled(Text, {
  marginTop: 24,
  textAlign: 'center',
  width: 24,
});

export const StyledStackContainer = styled(Stack, {
  borderRadius: 8,
  marginTop: 40,
  overflow: 'hidden',
  '@sm': {
    flex: '0 1 1',
    marginTop: 32,
  },
  '@md': {
    flex: '0 1 calc(33% - 1em)',
  },
});

export const StyledTimer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  backgroundColor: '$YellowLighter',
  color: DefaultColors.Neutral13,
  padding: '5px 24px 6px',
  width: '100%',
});

export const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
  {
    [`& .${tooltipClasses.arrow}`]: {
      color: DefaultColors.Neutral11,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: DefaultColors.Neutral11,
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      padding: '8px 16px',
      p: {
        color: DefaultColors.Neutral00,
      },
    },
  }
);

export const StyledTotalsContainer = styled('section', {
  padding: '16px 24px',
  [`${StyledItemRow}`]: {
    [`+ ${StyledItemRow}`]: {
      marginTop: 8,
    },
  },
});

export const StyledTracker = styled(Stepper, {
  margin: '32px 0',
});

export const StyledTrackerConnector = styled(StepConnector, {
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '$GreenBrand',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '$GreenBrand',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '$Neutral05',
    borderTopWidth: 3,
    borderRadius: 1,
  },
});

export const StyledTrackerIcon = styled('div', {
  color: '$GreenBrand',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  // ...(ownerState.active && {
  //   color: '#784af4',
  // }),
  '& .TrackerStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .TrackerStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
});
