import { observer } from 'mobx-react-lite';
import React from 'react';

import { darkTheme, defaultTheme, Theme as MuiTheme } from '@goodfynd/react-web.theme';

import { useStores } from '../stores';

export const Theme = observer(({ children }: BaseProps) => {
  const { isDarkMode } = useStores();
  const theme = isDarkMode ? darkTheme : defaultTheme;
  return (
    <MuiTheme isDarkMode={isDarkMode}>
      <div className={theme}>{children}</div>
    </MuiTheme>
  );
});

export default Theme;
