import { styled } from '@goodfynd/react-web.theme';
import { Button } from '@goodfynd/react-web.ui.button';
import TextField from '@mui/material/TextField';

import Container from '../../../components/layout/Container';

export const StyledForm = styled('form', {
  boxShadow:
    '0px 4px 20px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.05)',
  left: 0,
  position: 'absolute',
  top: 64,
  width: '100%',
});

export const StyledInputsContainer = styled(Container, {
  justifyContent: 'center',
  paddingBottom: 16,
  paddingTop: 4,
  '@md': {
    display: 'flex !important',
  },
});

export const StyledSearchButton = styled(Button, {
  '&.MuiButton-root': {
    boxShadow: 'none',
    marginLeft: 15,
    '@sm': {
      marginLeft: 0,
      marginTop: 16,
      width: '100%',
    },
  },
});

export const StyledSearchTextField = styled(TextField, {
  '&.MuiTextField-root input': {
    padding: '12px 16px',
  },
  '@sm': {
    width: '100%',
  },
  '@md': {
    width: 285,
  },
  '@lg': {
    width: 535,
  },
  variants: {
    position: {
      'input-bottom': {
        '> div': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          top: -1,
        },
      },
      'input-left': {
        '> div': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
      },
      'input-right': {
        '> div': {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          left: -1,
        },
      },
      'input-top': {
        '> div': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
  },
});
