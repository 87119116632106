import type { CSS} from '@stitches/react';
import { styled } from '@stitches/react';

import NextLinkComposed from './NextLinkComposed';

const linkBaseStyles: CSS = {
  color: '$GreenBrand',
  textDecoration: 'none',
};

export const StyledHtmlLink = styled('a', {
  ...linkBaseStyles,
  variants: {
    highlighted: {
      true: {
        color: '$GreenBrand',
      },
    },
    underlined: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
});

export const StyledLink = styled(NextLinkComposed, {
  ...linkBaseStyles,
  variants: {
    highlighted: {
      true: {
        color: '$GreenBrand',
      },
    },
    underlined: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
});
