import { GtmEvent, GtmPageView } from '../types/gtm';

export const pushEvent = (data: GtmEvent) => {
  if (typeof window === undefined || typeof window.dataLayer === undefined) {
    return;
  }

  console.debug('...Sending GTM Event:\n', data, window.dataLayer);
  // data.ecommerce && window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  // data.list && window.dataLayer.push({ list: null });
  window.dataLayer?.push(function () {
    //@ts-ignore
    this.reset();
  });
  window.dataLayer?.push(data);
};

export const trackPage = (pageView: GtmPageView) => {
  if (typeof window === undefined || typeof window.dataLayer === undefined) {
    return;
  }

  console.debug(
    '...Sending GTM Page View Event:\n',
    pageView,
    window.dataLayer
  );

  // Reset data layer
  window.dataLayer?.push(function () {
    //@ts-ignore
    this.reset();
  });

  // window.dataLayer.push(pageView);
};
