import React, { useState } from 'react';

import Button from '@mui/material/Button';

import type { NavLink } from '../layout/Nav/types';
import DropdownList from './DropdownList';

/* This one is an example of a Dropdown that is generated purely from data, using no children */

export interface SimpleDropdownProps {
  anchorText: string;
  children: React.ReactNode;
  items: NavLink[];
}

export const SimpleDropdown = ({
  anchorText,
  items,
}: SimpleDropdownProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {anchorText}
      </Button>

      <DropdownList
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={items}
        open={open}
      />
    </div>
  );
};

export default SimpleDropdown;
