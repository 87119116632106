import { Button } from '@goodfynd/react-web.ui.button';

import { styled } from '../../../theme/stitches.config';

export const StyledAnchorButton = styled(Button, {
  '&.MuiButton-root': {
    minWidth: 'auto',
    padding: '15px 12px 17px !important',
  },
});

export const StyledCloseButton = styled(StyledAnchorButton, {
  '&.MuiButton-root': {
    padding: '0 !important',
    position: 'absolute',
    right: 24,
    top: 32,
    zIndex: 5,
  },
});

export const StyledScrollContent = styled('div', {
  overflowY: 'auto',
});

export const StyledHeader = styled('div', {
  backgroundColor: '$White',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  padding: 16,
});
