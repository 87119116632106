import React, { useCallback, useState } from 'react';

import { useLikeApi } from '../../hooks/api';
import { gtmUtil } from '../../utils';
import { Icon } from '../Icon';
import Loading from '../Loading';
import { StyledButton, StyledIconButton } from './styles';

import type { GtmActionItemEvent } from '../../types/gtm';
import type { LikeButtonProps } from './types';
export default function LikeButton({
  buttonStyle = 'default',
  count,
  item,
  liked,
  type,
  ...props
}: LikeButtonProps) {
  const likeApi = useLikeApi();
  const [busy, setBusy] = useState(false);
  const [isLiked, toggleLiked] = useState(liked);
  const [likes, setLikes] = useState(count);

  const handleClick = useCallback(async () => {
    try {
      setBusy(true);

      const toggleLike = async (): Promise<{
        likes: number;
        liked: boolean;
      }> => {
        const entityType = type === 'event' ? 'lot' : type;

        await likeApi.toggleLike({
          entityId: item.id,
          entityType: entityType,
          isLiked: isLiked,
        });
        return { likes: isLiked ? likes - 1 : likes + 1, liked: !isLiked };
      };

      const toggleResult = await toggleLike();
      setLikes(toggleResult.likes);
      toggleLiked(toggleResult.liked);

      const gtmData: GtmActionItemEvent = {
        event: 'fynd_favorite',
        action: toggleResult.liked ? 'add' : 'remove',
        item_id: item.id,
        item_name: item.uniqueName || item.name,
      };
      gtmUtil.pushEvent(gtmData);
    } catch (error) {
      console.warn(error);
    } finally {
      setBusy(false);
    }
  }, [isLiked, item.id, item.name, item.uniqueName, likeApi, likes, type]);

  const commonProps = {
    disabled: busy,
    onClick: handleClick,
    ...props,
  };

  const iconName = isLiked ? 'heart_filled' : 'heart';
  switch (buttonStyle) {
    case 'default':
      return (
        <StyledButton
          disableElevation
          size="sm"
          startIcon={
            busy ? (
              <Loading size={16} type="circle" />
            ) : (
              <Icon name={iconName} size={18} />
            )
          }
          display="tertiary"
          {...commonProps}
        >
          {likes > 0 && <span>&nbsp;{likes}</span>}
          {/* {liked ? strings.labels.unFavorite : strings.labels.favorite} */}
        </StyledButton>
      );

    case 'icon':
      return (
        <StyledIconButton
          icon={busy ? <Loading size={16} type="circle" /> : iconName}
          round
          display="tertiary"
          {...commonProps}
        />
      );
  }
}
