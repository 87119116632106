import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { createContext, useRef, useState } from 'react';

import routes from '../../config/routes';
import { useAuth } from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';
import { useStores } from '../../stores';
import { UserInfo } from '../../stores/models/user';

import type {
  AppContextValue,
  AppDispatchContextValue,
  AppProviderProps,
} from './types';

export const AppContext = createContext<AppContextValue | undefined>(undefined);
export const AppDispatchContext = createContext<
  AppDispatchContextValue | undefined
>(undefined);

export default observer(function AppProvider({ children }: AppProviderProps) {
  const { isDarkMode = false, toggleDarkMode } = useStores();
  const router = useRouter();

  const defaultUserValue = useRef<UserInfo>({
    isAuthenticated: undefined,
    isGuest: true,
  } as UserInfo);
  const [user, setUser] = useState<UserInfo>(defaultUserValue.current);
  const setGuest = () => {
    setUser(defaultUserValue.current);
  };
  const notFound = () => {
    router.push(routes.account.profile);
  };
  const {
    isFetched: userIsFetched,
    isLoading: userIsLoading,
    refetch: refreshAuth,
  } = useUser({ setUser, setGuest, notFound });

  const {
    getLoginUrl,
    isAuthenticated,
    isLoading: identityLoading,
    logout,
    user: identity,
  } = useAuth();

  return (
    <AppContext.Provider
      value={{
        identity,
        identityLoading,
        isDarkMode,
        isLoggedIn: isAuthenticated,
        user,
        userIsFetched,
        userIsLoading,
      }}
    >
      <AppDispatchContext.Provider
        value={{
          getLoginUrl,
          logout,
          refreshAuth,
          setUser,
          toggleDarkMode,
        }}
      >
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
});
