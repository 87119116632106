import { Menu, MenuItem } from '@mui/material';

import { styled } from '../../theme/stitches.config';
import Button from '../Button';
import Container from '../layout/Container';

export const StyledAnchor = styled('div', {});

export const StyledMenuToggle = styled(Button, {
  '&.MuiButton-root': {
    backgroundColor: '$White',
    borderColor: '$Neutral05',
    borderRadius: '16px',
    marginTop: 16,
    '+ .MuiButton-root': {
      marginLeft: 8,
    },
    '@lg': {
      marginTop: 0,
    },
  },
});

export const StyledMenu = styled(Menu, {
  '.MuiPaper-root': {
    borderRadius: 6,
    minWidth: 200,
    marginTop: 4,
    shadow: 2,
    '&.MuiMenu-list': {
      padding: '4px 0',
    },
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

export const StyledMenuItem = styled(MenuItem, {
  '&.MuiMenuItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '&:hover': {
      color: '$Neutral13',
      svg: {
        color: '$Neutral13',
      },
      '.MuiTypography-root': {
        color: '$Neutral13',
      },
    },
  },
});

export const StyledMenuLabel = styled('span', {
  alignItems: 'center',
  display: 'flex',
  '.MuiAvatar-root': {
    marginRight: 8,
  },
  svg: {
    marginRight: 8,
  },
});

export const StyledContainer = styled(Container, {
  '&.MuiContainer-root': {
    '@sm': {
      paddingLeft: 0,
      paddingRight: 0,
      paddgingTop: 0,
    },
    '@md': {
      paddingTop: 32,
    },
  },
});
