import { styled } from '../../theme/stitches.config';
import Button from '../Button';

export const StyledButton = styled(Button, {
  display: 'inline',
  '&.MuiButton-root': {
    borderRadius: 4,
    boxShadow: 'none',
    '.MuiButton-startIcon': {
      marginRight: 0,
    },
  },
});

export const StyledIconButton = styled(Button, {
  '&.MuiButton-root': {},
});
