import { observer } from 'mobx-react-lite';
import React from 'react';

import { DarkColors, DefaultColors } from '@goodfynd/react-web.theme';
import { Icon as ReusableIcon } from '@goodfynd/react-web.ui.icon';

import { useStores } from '../../stores';
import { StyledBadge, StyledContainer } from './styles';

import type { ColorName, IconProps } from '@goodfynd/react-web.ui.icon';

export const Icon = observer(
  ({
    badge,
    badgeStyle,
    color = 'Neutral13',
    ignoreDarkMode,
    name = 'truck',
    size = 24,
    ...props
  }: IconProps) => {
    const { isDarkMode } = useStores();

    const renderIcon = () => (
      <ReusableIcon
        color={
          (isDarkMode && !ignoreDarkMode
            ? (DarkColors[color] as ColorName)
            : (DefaultColors[color] as ColorName)) || color
        }
        name={name}
        size={size}
        {...props}
      />
    );
    return !!badge ? (
      <StyledContainer>
        {renderIcon()}
        {!!badge && (
          <StyledBadge alignment="center" css={badgeStyle}>
            {badge}
          </StyledBadge>
        )}
      </StyledContainer>
    ) : (
      renderIcon()
    );
  }
);
