import React, { useCallback, useRef } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import Text from '../../Text';
import { StyledLabel } from './styles';

import type { InputRadioGroupControlProps } from '../types';
export default function InputRadioGroupControl<T = string>({
  defaultValue,
  label,
  onChange,
  options,
  showLabel = true,
  value,
}: Omit<InputRadioGroupControlProps<T>, 'control'>) {
  const selectedOption = useRef(defaultValue || null);
  const renderOptions = useCallback(() => {
    return options.map((option) => (
      <FormControlLabel
        control={<Radio />}
        key={typeof option.label === 'string' ? option.label : option.value}
        label={
          <StyledLabel>
            <Text type="body1">{option.label}</Text>
            <Text>{option.labelRight}</Text>
          </StyledLabel>
        }
        value={option.value}
      />
    ));
  }, [options]);

  // defaultValue && alert(defaultValue);
  return (
    <FormControl fullWidth variant="outlined">
      {showLabel &&
        (typeof label === 'string' ? (
          <FormLabel component="legend">{label}</FormLabel>
        ) : (
          label
        ))}

      <RadioGroup
        defaultValue={selectedOption?.current}
        onChange={onChange}
        value={value}
      >
        {renderOptions()}
      </RadioGroup>
    </FormControl>
  );
}
