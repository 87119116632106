import { styled } from '@goodfynd/react-web.theme';
import { Button } from '@goodfynd/react-web.ui.button';

export const StyledContainer = styled('aside', {
  maxWidth: '100%',
  paddingBottom: 244,
  position: 'relative',
  width: 400,
});

export const StyledIconButton = styled(Button, {
  '&.MuiButton-root': {
    minWidth: 'auto',
    padding: '15px 12px 17px !important',
  },
});

export const StyledTitleContainer = styled('div', {
  padding: '30px 24px',
});

export const StyledTotalsContainer = styled('section', {
  backgroundColor: '$White',
  bottom: 0,
  boxShadow:
    '0px -4px 20px rgba(0, 0, 0, 0.05), 0px -2px 12px rgba(0, 0, 0, 0.05)',
  maxWidth: '95%',
  position: 'fixed',
  right: 0,
  width: 400,
});
