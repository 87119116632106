import React, { useState } from 'react';

import Button from '../../components/Button';
import Link from '../../components/Link';
import PickupTimeOptions from '../../components/PickupTimeOptions';
import Text from '../../components/Text';
import config from '../../config';
import routes from '../../config/routes';
import strings from '../../config/strings';
import { dateUtil } from '../../utils';
import OrderTracker from './OrderTracker';
import {
  StyledIconLabel,
  StyledMerchantContainer,
  StyledOrderConfirmation,
} from './styles';

import type { UpdatePickupRequest } from '../../types/orders';
import type { OrderMerchantProps } from './types';

export default function OrderMerchant({
  customer,
  index = 0,
  order,
  showConfirmation,
  updatePickupTime,
  ...props
}: OrderMerchantProps) {
  const [viewPickupTimeOptions, togglePickupTimeOptions] = useState(false);

  const handlePickupUpdate = async (pickupTime: Date) => {
    const request: UpdatePickupRequest = {
      id: order.id,
      pickupTime,
      scheduleId: order.scheduleId,
    };

    try {
      updatePickupTime(request);
      togglePickupTimeOptions(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const customerEmail = order.customer?.email || customer?.email;
  return (
    <>
      <StyledMerchantContainer
        {...props}
        css={{
          ...props.css,
          borderTop: index > 0 ? '5px solid $Neutral02' : undefined,
          marginTop: 16,
        }}
      >
        {showConfirmation && (
          <StyledOrderConfirmation>
            <Text
              as="h5"
              css={{
                fontSizeRem: 26,
                lineHeightRem: 32,
              }}
              type="h5"
            >
              {order.step === 91
                ? strings.titles.orderCanceled
                : order.step === 93
                ? strings.titles.orderRefunded
                : strings.titles.orderReceived}
            </Text>

            {order.step >= 90 ? (
              <Text type="body2">
                {order.cancellationReason || order.refundNotes}
              </Text>
            ) : (
              customerEmail && (
                <Text type="body2">{`${strings.titles.confirmationEmailSent} ${customerEmail}`}</Text>
              )
            )}

            <OrderTracker order={order} />

            <Link
              href={`${config.env.RECEIPT_HOST}/orders/receipt/image/${order.transactionId}`}
            >
              <Text
                css={{ display: 'inline-block', marginLeft: 24 }}
                inherit
                type="subh2"
              >
                {strings.labels.viewReceipt}
              </Text>
            </Link>

            <Link href={routes.search.products}>
              <Text
                css={{ display: 'inline-block', marginLeft: 24 }}
                inherit
                type="subh2"
              >
                {strings.labels.findMoreFood}
              </Text>
            </Link>
          </StyledOrderConfirmation>
        )}

        <Link href={`/vendors/${order.merchant.nameId}`}>
          <Text type="subh2">{order.merchant.name}</Text>
        </Link>

        {order.pickupTime && (
          <>
            <Link href={order.pickupLocation.directions as string} underlined>
              <StyledIconLabel icon="location_pin" inherit>
                {order.pickupLocation.name || order.pickupLocation.address}
              </StyledIconLabel>
            </Link>

            {!!order.pickupTime && (
              <StyledIconLabel icon="calendar">
                {dateUtil.format(
                  order.pickupTime as Date,
                  config.dateFormats.weekDayMonthDay
                )}
              </StyledIconLabel>
            )}

            {!!(order.pickupTimeEnd && order.pickupTimeStart) && (
              <StyledIconLabel icon="clock">
                {dateUtil.getTimeDisplay(
                  new Date(order.pickupTime as Date),
                  true,
                  true
                )}
              </StyledIconLabel>
            )}
          </>
        )}

        {order.step === 0 && (
          <Button
            css={{
              '&.MuiButton-root': {
                marginTop: 16,
                padding: '0',
              },
            }}
            onClick={() => togglePickupTimeOptions(true)}
            size="sm"
            variant="outlined"
          >
            {order.pickupTime
              ? strings.labels.updatePickupTime
              : strings.labels.choosePickupTime}
          </Button>
        )}
      </StyledMerchantContainer>

      {viewPickupTimeOptions && (
        <PickupTimeOptions
          close={() => togglePickupTimeOptions(false)}
          onContinue={handlePickupUpdate}
          open={viewPickupTimeOptions}
          scheduleId={order.scheduleId}
          vendorId={order.merchant.nameId}
        />
      )}
    </>
  );
}
