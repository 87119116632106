const routes = {
  home: '/',
  about: '/about',
  blog: 'https://blog.goodfynd.com',
  careers: '/careers',
  maintenance: '/maintenance',
  notFound: '/404',
  success: {
    eventLead: '/success/event-lead',
    register: '/success/register',
    resetPassword: '/success/reset-password',
    verifyEmail: '/success/verify-email',
    vendorLead: '/success/vendor-lead',
  },
  account: {
    blocked: '/auth/blocked',
    callback: '/auth/callback',
    forgotPassword: '/forgot-password',
    home: '/profile/favorites',
    orderHistory: '/profile/order-history',
    personalInfo: '/profile/personal-info',
    requestDelete: '/profile/request-delete',
    preferences: '/profile/preferences',
    orders: '/profile/orders',
    signIn: '/auth/login',
    logout: '/auth/logout',
    profile: '/auth/profile',
    signUp: '/auth/signup',
    unverified: '/auth/unverified',
  },
  apps: {
    android:
      'https://play.google.com/store/apps/details?id=com.goodfynd.trucks',
    ios: 'https://apps.apple.com/us/app/goodfynd/id1402316922?ls=1',
  },
  checkout: {
    home: '/checkout',
    review: '/checkout/review',
    thanks: '/checkout/thanks/:transactionIds',
  },
  search: {
    home: '/search',
    events: '/search/events',
    products: '/search/products',
    vendors: '/search/vendors',
  },
  help: {
    home: '/help',
    chat: '/chat',
  },
  legal: {
    home: '/legal',
    california: '/legal/privacy-california',
    cookies: '/legal/cookies-policy',
    fulfillment: '/legal/fulfillment-policy',
    privacy: '/legal/privacy',
    terms: '/legal/terms',
  },
  events: {
    home: '/events',
    details: '/events/:id',
  },
  products: {
    category: '/vendors/:id/categories/:categoryId',
    details: '/vendors/:vendorId/products/:id',
  },
  social: {
    facebook: 'https://www.facebook.com/goodfynd',
    instagram: 'https://www.instagram.com/goodfynd/',
    linkedIn: 'https://www.linkedin.com/company/goodfynd',
    twitter: 'https://twitter.com/goodfynd',
  },
  vendors: {
    home: '/vendors',
    add: 'https://vendors.goodfynd.com/add',
    details: '/vendors/:id',
  },
  schedules: {
    details: '/schedules/:id',
    product: '/schedules/:scheduleId/products/:id',
  },
};

const driftRoutes = [
  routes.home,
  routes.events.home,
  routes.search.home,
  routes.search.events,
  routes.search.products,
  routes.search.vendors,
  routes.vendors.home,
];

const externalRoutes = {
  events: {
    add: 'https://events.goodfynd.com/add',
    myEvents: 'https://events.goodfynd.com/dashboard/:id',
    home: 'https://events.goodfynd.com',
  },
  vendors: {
    add: 'https://vendors.goodfynd.com/add',
    myBusinesses: 'https://vendors.goodfynd.com/dashboard/:id',
    home: 'https://vendors.goodfynd.com',
  },
};

export { driftRoutes, externalRoutes };
export default routes;
