import { styled } from '../../theme/stitches.config';
import Text from '../Text';

export const StyledBadge = styled(Text, {
  backgroundColor: '$RedBrand',
  borderRadius: '100%',
  color: '$White',
  fontSizeRem: 10,
  height: 20,
  lineHeightRem: 20,
  position: 'absolute',
  right: -5,
  top: -5,
  width: 20,
});

export const StyledContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});
