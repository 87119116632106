import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { StyledHtmlLink, StyledLink } from './styles';

import type { LinkProps } from './types';

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  props,
  ref
) {
  const {
    activeClassName = 'active',
    as: linkAs,
    className: classNameProps,
    highlighted,
    href,
    noLinkStyle,
    reload,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href?.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router?.pathname === pathname && activeClassName,
  });

  const otherProps = other.onClick
    ? {
        ...other,
        css: {
          cursor: 'pointer',
          ':hover': {
            color: '$GreenBrand',
          },
        },
      }
    : {
        ...other,
        css: {
          ':hover': !!href && {
            color: '$GreenBrand',
          },
        },
      };

  if (!href) {
    if (noLinkStyle) {
      return (
        <StyledHtmlLink
          className={className}
          highlighted={highlighted}
          ref={ref}
          {...otherProps}
        />
      );
    }

    return (
      <StyledHtmlLink
        className={className}
        highlighted={highlighted}
        ref={ref}
        {...otherProps}
      />
    );
  }
  const isExternal =
    typeof href === 'string' &&
    (href.startsWith('http') || href.startsWith('mailto:'));

  if (isExternal || reload) {
    const target = isExternal ? '_blank' : undefined;
    if (noLinkStyle) {
      return (
        <StyledHtmlLink
          className={className}
          highlighted={highlighted}
          href={href as string}
          ref={ref}
          target={target}
          {...otherProps}
        />
      );
    }

    return (
      <StyledHtmlLink
        className={className}
        highlighted={highlighted}
        href={href as string}
        ref={ref}
        target={target}
        {...otherProps}
      />
    );
  }

  if (noLinkStyle) {
    return (
      <StyledLink
        className={className}
        highlighted={highlighted}
        ref={ref}
        to={href}
        {...otherProps}
      />
    );
  }

  return (
    <StyledLink
      className={className}
      highlighted={highlighted}
      linkAs={linkAs}
      ref={ref}
      to={href}
      {...otherProps}
    />
  );
});

export default Link;
