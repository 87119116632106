import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';

import { Logo } from '@goodfynd/react-web.ui.logo';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import config from '../../../config';
import routes, { externalRoutes } from '../../../config/routes';
import strings from '../../../config/strings';
import { useApp } from '../../../context/app-context';
import { useAuth } from '../../../hooks/useAuth';
import { usePermissions } from '../../../hooks/usePermissions';
import { useStores } from '../../../stores';
import { useScreenMdSize } from '../../../utils/base';
import { Cart } from '../../checkout/Cart';
import { Icon } from '../../Icon';
import { NavWrapper } from '../Nav';
import { MAIN_NAV } from './header-links';
import {
  StyledAvatar,
  StyledContainer,
  StyledHeader,
  StyledMoreIconContainer,
  StyledNavRight,
  StyledUserMenu,
} from './styles';

import type { HeaderProps } from './types';
import type { MenuOption } from '../../Menu/types';
import { stringUtil } from '../../../utils';

export default observer(function Header({
  children,
  fixed = true,
  navLinks = MAIN_NAV,
  shadow = true,
  showNav = true,
}: HeaderProps) {
  const router = useRouter();
  const { isDarkMode = false, toggleDarkMode } = useStores();
  const { isVendorOwner, isLotOwner, isRestrictedAdmin } = usePermissions();

  const collapseMenu = useScreenMdSize();

  const { isLoggedIn, user } = useApp();

  const { logout } = useAuth();

  const renderAvatar = useCallback(() => {
    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
    const initials = `${user?.firstName?.[0] || ''}${
      user?.lastName?.[0] || ''
    }`.trim();
    return (
      <StyledAvatar
        alt={fullName}
        css={{
          border: user?.avi ? '2px solid $GreenBrand' : undefined,
        }}
        loggedIn={isLoggedIn}
        src={user?.avi}
      >
        {isLoggedIn && !!initials ? initials : <Icon name="person_circled" />}
      </StyledAvatar>
    );
  }, [isLoggedIn, user?.avi, user?.firstName, user?.lastName]);

  const userMenu = useMemo<MenuOption[]>(() => {
    const items: MenuOption[] = [
      {
        hide: isLoggedIn,
        icon: 'person',
        label: 'Login',
        onClick: () =>
          router.push(routes.account.signIn + `?returnUrl=${router.asPath}`),
      },
      {
        hide: !isLoggedIn,
        icon: collapseMenu ? renderAvatar() : 'person_circled',
        label: 'View Profile',
        onClick: () => router.push(routes.account.home),
      },
      {
        hide: !isLoggedIn,
        icon: 'sign_out',
        label: 'Sign Out',
        onClick: () => logout(),
      },
      {
        key: strings.labels.darkMode,
        label: (
          <FormControlLabel
            control={
              <Switch
                checked={isDarkMode}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDarkMode();
                }}
              />
            }
            label={strings.labels.darkMode}
          />
        ),
      },
    ];

    if (collapseMenu) {
      items.unshift(
        {
          label: strings.labels.findFood,
          onClick: () => router.push(routes.search.home),
        },
        {
          label: strings.labels.addYourTruck,
          onClick: () => router.push(routes.vendors.home),
        },
        {
          label: strings.labels.bookATruck,
          onClick: () => router.push(routes.events.home),
        },
        {
          divider: true,
          key: 'divider',
        }
      );
    }

    if (isLoggedIn && (isVendorOwner || isLotOwner || isRestrictedAdmin)) {
      const externalItems: MenuOption[] = [];

      (isVendorOwner || isRestrictedAdmin) &&
        externalItems.push({
          label: strings.labels.myBusinesses,
          onClick: () =>
            (window.location.href = user?.defaultVendorId
              ? stringUtil.replace(externalRoutes.vendors.myBusinesses, {
                  ':id': user?.defaultVendorId,
                })
              : externalRoutes.vendors.home),
        });

      (isLotOwner || isRestrictedAdmin) &&
        externalItems.push({
          label: strings.labels.myEvents,
          onClick: () =>
            (window.location.href = user?.defaultLotId
              ? stringUtil.replace(externalRoutes.events.myEvents, {
                  ':id': user?.defaultLotId,
                })
              : externalRoutes.events.home),
        });

      externalItems.push({
        divider: true,
        key: 'divider',
      });
      items.unshift(...externalItems);
    }

    return items;
  }, [
    isLoggedIn,
    collapseMenu,
    renderAvatar,
    isDarkMode,
    isVendorOwner,
    isLotOwner,
    isRestrictedAdmin,
    router,
    logout,
    toggleDarkMode,
    user?.defaultVendorId,
    user?.defaultLotId,
  ]);

  const buildHeader = () => {
    return (
      <StyledContainer>
        <Logo
          environment={config.env.NAME_LOWER}
          isDarkMode={isDarkMode}
          unoptimized
        />

        {showNav && (
          <StyledNavRight>
            <NavWrapper links={navLinks} variant="horizontal" />

            <Cart />

            <StyledUserMenu
              anchor={
                <>
                  {renderAvatar()}
                  <StyledMoreIconContainer>
                    <Icon name="more" />
                  </StyledMoreIconContainer>
                </>
              }
              anchorCSS={{
                '.MuiAvatar-root': {
                  '@mdMax': {
                    display: 'none',
                  },
                },
              }}
              options={userMenu}
            />
          </StyledNavRight>
        )}
      </StyledContainer>
    );
  };

  return (
    <StyledHeader fixed={fixed} shadow={shadow}>
      {children ? children : buildHeader()}
    </StyledHeader>
  );
});
