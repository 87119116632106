import NextLink from 'next/link';
import React from 'react';

import type { NextLinkComposedProps } from './types';

const NextLinkComposed = function NextLinkComposed(
  {
    to,
    linkAs,
    replace,
    scroll,
    passHref,
    shallow,
    prefetch,
    locale,
    ...other
  }: NextLinkComposedProps,
  ref: React.LegacyRef<HTMLAnchorElement>
) {
  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      locale={locale}
    >
      <a ref={ref} {...other} />
    </NextLink>
  );
};

export default React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  NextLinkComposed
);
