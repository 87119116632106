import { styled } from '../../theme/stitches.config';
import Text from '../Text';

export const StyledContainer = styled('aside', {
  alignItems: 'center',
  display: 'flex',
  gap: 16,
});

export const StyledLabelContainer = styled('div');

export const StyledText = styled(Text, {
  color: '$Neutral13 !important',
});
