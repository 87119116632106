import React from 'react';

import { AccordionDetails, AccordionSummary } from '@mui/material';

import config from '../../../config';
import strings from '../../../config/strings';
import { dateUtil, mapsUtil } from '../../../utils';
import { Icon } from '../../Icon';
import Image from '../../Image';
import Link from '../../Link';
import Modal from '../../Modal';
import Tag from '../../Tag';
import Text from '../../Text';
import {
  StyledAccordion,
  StyledMapContainer,
  StyledModalContent,
} from './styles';

import type { VendorScheduleProps } from './types';
const VendorSchedule = ({ events, vendor, ...props }: VendorScheduleProps) => {
  const [expanded, setExpanded] = React.useState('');

  const renderContent = () => {
    const handleChange =
      (entry: OperatingDayLocation) =>
      (_e: React.SyntheticEvent, isExpanded: boolean) =>
        setExpanded(isExpanded ? entry.id : '');

    return (
      <>
        <StyledModalContent>
          {events.map((entry) => {
            const isExpanded = expanded === entry.id;
            return (
              <StyledAccordion
                expanded={isExpanded}
                key={entry.id}
                onChange={handleChange(entry)}
              >
                <AccordionSummary
                  expandIcon={<Icon name="chevron_down" />}
                  aria-controls={`${entry.location.name || entry.id}-content`}
                  id={entry.id}
                >
                  <Text>{entry.location.name || entry.location.address}</Text>

                  <Text type="body2">
                    <span>
                      {dateUtil.format(
                        new Date(entry.start as string),
                        config.dateFormats.weekDayMonthDay
                      )}
                      {config.unicode.centerDotPadded}
                      {dateUtil.getTimeDisplay(
                        new Date(entry.start as string),
                        true,
                        true
                      )}
                      &nbsp;-&nbsp;
                      {dateUtil.getTimeDisplay(
                        new Date(entry.end as string),
                        true,
                        true
                      )}
                      &nbsp;
                      {entry.isPrivate && <Tag label="PRIVATE" />}
                    </span>
                  </Text>
                </AccordionSummary>

                <AccordionDetails>
                  <StyledMapContainer>
                    <Link href={entry.directions || ''} target="_blank">
                      <Image
                        alt={`Map of ${entry.location.name}`}
                        height={278}
                        width={600}
                        src={mapsUtil.getStaticMapImage(
                          entry.location.coordinates,
                          '600x278',
                          'roadmap'
                        )}
                      />
                    </Link>
                  </StyledMapContainer>

                  <Link
                    highlighted
                    href={entry.directions || ''}
                    target="_blank"
                    underlined
                  >
                    <Text
                      css={{ display: 'inline-block', marginTop: 8 }}
                      inherit
                      marginBottom={8}
                    >
                      {strings.labels.getDirections}
                    </Text>
                  </Link>

                  {!!entry.lotId && (
                    <Link
                      highlighted
                      href={`/events/${entry.lotId}`}
                      target="_blank"
                      underlined
                    >
                      <Text
                        css={{
                          display: 'inline-block',
                          marginLeft: 16,
                          marginTop: 8,
                        }}
                        inherit
                        marginBottom={8}
                        marginTop={8}
                      >
                        {strings.labels.viewEvent}
                      </Text>
                    </Link>
                  )}
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
        </StyledModalContent>
      </>
    );
  };

  return (
    <Modal
      aria-labelledby="vendor-schedule-modal"
      aria-describedby="vendor-schedule"
      gtmType="vendor_schedule"
      header={
        <Text as="h5" type="h5">
          {vendor.uniqueName}&nbsp;{strings.titles.foodTruckSchedule}
        </Text>
      }
      {...props}
    >
      {renderContent()}
    </Modal>
  );
};

export default VendorSchedule;
