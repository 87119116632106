import React from 'react';

import { styled } from '@goodfynd/react-web.theme';

import CartProvider from '../../context/cart-context/CartContext';
import CmsCollection from '../cms/CmsCollection';
import Theme from '../Theme';
import Footer from './Footer';
import Header from './Header';

const StyledLayout = styled('div', {
  fontSize: 16,
  display: 'block',
  paddingTop: 64,
  flexGrow: 1,
});

const StyledContainer = styled('div', {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  minWidth: '100vw',
});

type LayoutProps = {
  children: React.ReactNode;
  headerFixed?: boolean;
  headerShadow?: boolean;
  showNav?: boolean;
};

export default function Layout({
  children,
  headerFixed = true,
  headerShadow = true,
  showNav = true,
}: LayoutProps): JSX.Element {
  return (
    <CartProvider>
      <Theme>
        <Header fixed={headerFixed} shadow={headerShadow} showNav={showNav} />
        <StyledContainer>
          <StyledLayout>{children}</StyledLayout>

          {showNav ? (
            <CmsCollection
              fieldName="Footer Name"
              fieldValue="Standard Footer"
              slug="footers"
              type="footer"
            />
          ) : (
            <Footer showNav={showNav} />
          )}
        </StyledContainer>
      </Theme>
    </CartProvider>
  );
}
