import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import Loading from '../../components/Loading';
import config from '../../config';
import strings from '../../config/strings';
import { StyledSearchTextField } from '../../context/search-context/SearchInputs/styles';
import { useStores } from '../../stores';
import { StyledPromoForm, StyledPromoInput } from './styles';

import type { PromoFormProps } from './types';

export default function PromoForm({ appliedPromo, ...props }: PromoFormProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [promo, setPromo] = useState('');
  const { orderStore } = useStores();

  const applyPromo = async (e: ClickButtonEvent) => {
    e.preventDefault();
    setErrors([]);

    try {
      await props.applyPromo(promo);
      setPromo('');
    } catch (error: any) {
      console.warn(error);

      setErrors(
        error
          ? [
              error.message,
              ...(error.errors || [])
                .filter((error: ResponseError) => error.code !== 'trace')
                .map((error: ResponseError) => error.message),
            ]
          : []
      );
    }
  };

  return (
    <StyledPromoForm {...props}>
      <StyledPromoInput>
        <StyledSearchTextField
          css={{
            '&.MuiTextField-root': {
              marginRight: 8,
            },
          }}
          error={errors.length > 0}
          label={strings.labels.enterPromoCode}
          onChange={(e) => setPromo(e.target.value)}
          value={promo}
        />

        <Icon name="discount" size={32} />
      </StyledPromoInput>

      {!!promo && (
        <Button
          disabled={!promo || orderStore.busy}
          fullWidth
          onClick={applyPromo}
          size="md"
          style={{ marginTop: 16 }}
          type="submit"
        >
          {orderStore.busy ? <Loading type="line" /> : strings.labels.apply}
        </Button>
      )}

      {errors.length > 0 && (
        <Alert css={{ marginTop: 16 }} type="error">
          {errors}
        </Alert>
      )}

      {!!appliedPromo && (
        <Alert
          css={{ marginTop: 16 }}
          type="success"
        >{`${appliedPromo} ${strings.labels.promoApplied}`}</Alert>
      )}
    </StyledPromoForm>
  );
}
