import React, { useState } from 'react';

import { Divider } from '@mui/material';

import { Icon } from '../Icon';
import {
  StyledAnchor,
  StyledMenu,
  StyledMenuItem,
  StyledMenuLabel,
} from './styles';

import type { MenuOption, MenuProps } from './types';

export default function Menu({
  anchor,
  anchorCSS,
  onChange,
  options = [],
  selected,
  ...props
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleMenuClick = (e: ClickButtonEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOptionSelect = (option: MenuOption) => {
    option.onClick?.(option);
    onChange?.(option);
    handleClose();
  };

  if (options.length === 0) {
    return null;
  }

  const selectedValue = Array.isArray(selected) ? undefined : selected;
  return (
    <>
      <StyledAnchor css={anchorCSS} onClick={handleMenuClick}>
        {anchor}
      </StyledAnchor>

      <StyledMenu
        id="search-filter-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        {...props}
      >
        {options
          .filter((option) => !option.hide)
          .map((option) =>
            option.divider ? (
              <Divider key={option.key} />
            ) : (
              <StyledMenuItem
                key={option.value || option.key || option.label?.toString()}
                onClick={() => handleOptionSelect(option)}
                disableRipple
              >
                <StyledMenuLabel>
                  {typeof option.icon === 'string' ? (
                    <Icon name={option.icon} />
                  ) : (
                    option.icon
                  )}
                  {option.label}
                </StyledMenuLabel>

                {selectedValue && option.value === selectedValue && (
                  <Icon name="check" />
                )}
              </StyledMenuItem>
            )
          )}
      </StyledMenu>
    </>
  );
}
